<template>
  <div
    class="ChallengeItem"
    @click="navigateToChallengeResult()">
    <div class="body">
      <div class="opponentAvatar" />
      <div class="opponentName">
        name of opponent
      </div>
      <div
        v-if="compare"
        class="result">
        {{ $t(`compare.${compare}`) }}
      </div>
      <div
        v-else
        class="pending">
        {{ $t('pending') }}
      </div>
    </div>
    <div class="dbg">
      members: {{ group.membersByGroupIdList.length }}, results:
      {{ challenge.leaderboardsByChallengeIdList.length }}
    </div>
  </div>
</template>

<translations>
  compare:
    loose: 'Lost'
    win: 'Won'
    draw: 'Draw'
  pending: 'Pending...'
</translations>

<script>
export default {
    props: {
        groupId: {
            type: String,
            required: true
        }
    },
    computed: {
        group() {
            return this.$store.getters['moduleSocial/getGroup'](this.groupId);
        },
        challenge() {
            return this.group.challengesByGroupIdList[0];
        },
        // settings () {
        //   return JSON.parse(this.challenge.settings)
        // },
        me() {
            const result = this.challenge.leaderboardsByChallengeIdList.find(
                x => x.userId === this.$store.state.moduleSocial.userId
            );
            return {
                score: result ? result.score : null
            };
        },
        you() {
            // Assume: only one opponent
            const result = this.challenge.leaderboardsByChallengeIdList.find(
                x => x.userId !== this.$store.state.moduleSocial.userId
            );

            return {
                name: 'name of opponent',
                score: result ? result.score : null
            };
        },
        compare() {
            if (this.me.score === null || this.you.score === null) {
                return null;
            }
            if (this.me.score < this.you.score) {
                return 'win';
            } else if (this.me.score > this.you.score) {
                return 'loose';
            } else {
                return 'draw';
            }
        }
    },
    methods: {
        navigateToChallengeResult() {
            this.$router.push({
                name: 'MemogeniusGame-ChallengeResult',
                params: {
                    challengeId: this.challenge.id
                }
            });
        }
    }
};
</script>

<style scoped lang="scss">
.ChallengeItem {
    padding: 1em;
}
.body {
    display: flex;
    align-items: center;
}
.opponentAvatar {
    $size: 3em;
    width: $size;
    height: $size;
    border-radius: 50%;
    background-color: gray;
    margin-right: 1em;
}
.opponentName {
    flex: 1;
}
.result {
    background-color: white;
    color: black;
    border-radius: 0.2em;
    padding: 0.5em;
}

.dbg {
    // border: 1px solid red;
    // padding: 1em;
    // margin-top: 1em;
    color: rgba(white, 0.4);
}
</style>
