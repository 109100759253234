<template>
  <div class="ChallengesView">
    <div class="header">
      <div class="title">
        Challenges
      </div>
      <div
        class="backButton --global-clickable"
        @click="navigateBack()">
        Go back
      </div>
    </div>
    <div class="body">
      <AvatarVsAvatar />
      <!-- <div>Challenges</div> -->
      <!-- {{ challenges }} -->
      <br>
      <!-- <div class="pending">
        2 challenges pending -- {{ groups.length }}
      </div> -->
      <br><br>
      <div>
        <ChallengeItem
          v-for="group of groups"
          :key="group.id"
          class="--global-clickable"
          :group-id="group.id" />
      </div>
    </div>

    <div class="footer">
      <div
        class="createChallengeButton --global-clickable"
        @click="navigateToCreate()">
        Create New Challenge
      </div>
    </div>
  </div>
</template>

<script>
import AvatarVsAvatar from '../components/AvatarVsAvatar';
import ChallengeItem from '../components/ChallengeItem';

export default {
    components: { AvatarVsAvatar, ChallengeItem },
    props: {
        gameId: {
            type: String,
            required: true
        }
    },
    computed: {
        groups() {
            return this.$store.getters['moduleSocial/privateGroups'].filter(group => {
                const challenge = group.challengesByGroupIdList[0];
                if (challenge) {
                    const settings = JSON.parse(challenge.settings);
                    if (settings.gameId === this.gameId && challenge.challengeType === 'memogeniusSingleSession') {
                        return true;
                    }
                }
            });
        },
        challenges() {
            return this.groups.map(group => {
                return group.challengesByGroupIdList[0];
            });
        }
    },
    mounted() {
        this.$store.dispatch('moduleSocial/refresh');
    },
    methods: {
        navigateBack() {
            this.$router.push({
                name: 'MemogeniusGame-InitialView'
            });
        },
        navigateToCreate() {
            this.$router.push({
                name: 'MemogeniusGame-ChallengesCreate'
            });
        }
    }
};
</script>

<style scoped lang="scss">
$sideMargin: 2em;
.ChallengesView {
    display: flex;
    flex-direction: column;
}
.header {
    position: relative;
    height: 30%;
    background-color: rgba(black, 0.1);
}
.title {
    font-weight: 600;
    text-align: center;
    padding: 1em;
}
.backButton {
    color: rgba(white, 0.5);
    position: absolute;
    padding: 1em;
    top: 0em;
    left: 0;
}
.AvatarVsAvatar {
    margin-top: -2.25em;
}
.body {
    flex: 1;
}

.pending {
    padding: 1em;
    background-color: rgba(white, 0.1);
}

.footer {
    padding: 1em $sideMargin;
}

.createChallengeButton {
    background-color: blue;
    color: white;
    padding: 1em 2em;
    border-radius: 2em;
    text-align: center;
}
</style>
